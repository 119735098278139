import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Layout, SEO } from "../components/Layout"
import MailIllustration from "../images/mail.svg"

const SuccessPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <section className="misc-page">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-7 col-lg-6 text-center text-sm-left text-white">
              <FormattedMessage id="successPage.heading">
                {txt => <h1 className="pt-3">{txt}</h1>}
              </FormattedMessage>

              <FormattedMessage id="successPage.description">
                {txt => <p className="pt-2 pb-5 py-sm-3 py-lg-4">{txt}</p>}
              </FormattedMessage>
            </div>
            <div className="col-12 col-sm-5 col-lg-6">
              <img className="w-100" src={MailIllustration} alt="" />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SuccessPage
